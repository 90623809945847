import React, { useState } from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import "../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { addBrand, updateBrand } from "../../services/brandApi";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { pinCode, validateFullName, validatePhone, validEmail } from "./Regex";

const CreateBrand = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [wareHouseModelChecked, setWareHouseModelChecked] = useState(
    state?.wareHouseModel || false
  );
  const [offerPriceEnabledChecked, setOfferPriceEnabledChecked] = useState(
    state?.offerPriceEnabled || false
  );
  const [brandOffModelChecked, setBrandOffModelChecked] = useState(
    state?.brandOffModel || false
  );
  const [orderEditModelChecked, setOrderEditModelChecked] = useState(
    state?.orderEditModel || false
  );
  const [webLoginEnabledChecked, setWebLoginEnabledChecked] = useState(
    state?.webLoginEnabled || false
  );

  const [input, setInput] = useState({
    name: state?.name || "",
    mobileNo: state?.mobileNo || "",
    email: state?.email || "",
    notifyEmail: state?.notifyEmail || "",
    notifyMobileNo: state?.notifyMobileNo || "",
    addressLine1: state?.address?.addressLine1 || "",
    addressLine2: state?.address?.addressLine2 || "",
    city: state?.address?.city || "",
    landmark: state?.address?.landmark || "",
    state: state?.address?.state || "",
    pin: state?.address?.pin || "",
  });
  const [error, setError] = useState({
    name: "",
    mobileNo: "",
    email: "",
    notifyEmail: "",
    notifyMobileNo: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    landmark: "",
    state: "",
    pin: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newError = { ...error };

    if (!validateFullName.test(input.name)) {
      newError.name =
        "Brand Name can not be empty, it should be greater then 2 alphabets ";
      isValid = false;
    } else {
      newError.name = "";
    }
    if (!validatePhone.test(input.mobileNo)) {
      newError.mobileNo = "Please Enter Valid 10 Digit Mobile Number";
      isValid = false;
    } else {
      newError.mobileNo = "";
    }

    if (!validEmail.test(input.email)) {
      newError.email = "Please Enter Valid Email Address";
      isValid = false;
    } else {
      newError.email = "";
    }
    if (input.notifyEmail === "") {
      newError.notifyEmail = "Please Enter Valid Notify Email Address";
      isValid = false;
    } else {
      newError.notifyEmail = "";
    }

    if (!validatePhone.test(input.notifyMobileNo)) {
      newError.notifyMobileNo = "Please Enter Valid 10 Digit Notify Mobile No";
      isValid = false;
    } else {
      newError.notifyMobileNo = "";
    }

    if (input.addressLine1 === "") {
      newError.addressLine1 = "Please Enter Address Line1";
      isValid = false;
    } else {
      newError.addressLine1 = "";
    }
    if (input.state === "") {
      newError.state = "Please Enter Correct State";
      isValid = false;
    } else {
      newError.state = "";
    }

    if (!pinCode.test(input.pin)) {
      newError.pin = "Please Enter 6 Digit PinCode";
      isValid = false;
    } else {
      newError.pin = "";
    }

    setError(newError);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      let data = {
        name: input.name,
        mobileNo: input.mobileNo,
        email: input.email,
        address: {
          addressLine1: input.addressLine1,
          addressLine2: input.addressLine2,
          city: input.city,
          landmark: input.landmark,
          state: input.state,
          pin: input.pin,
          default: false,
        },
        // registrationDate: null, // createdDate
        notifyEmail: input.notifyEmail.replace(/ /g, ", "),
        notifyMobileNo: input.notifyMobileNo,
        // brandCode: "DCOIT", // non editable
        wareHouseModel: wareHouseModelChecked,
        offerPriceEnabled: offerPriceEnabledChecked,
        brandOffModel: brandOffModelChecked,
        orderEditModel: orderEditModelChecked,
        webLoginEnabled: webLoginEnabledChecked,
      };

      const updateData = {
        ...data,
        id: state?.id,
      };

      if (state !== null) {
        updateBrand(updateData)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/all-brands");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      } else {
        addBrand(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/all-brands");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      }
    }
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <div className="mt-3" style={{ textAlign: "center" }}>
          <h3>
            {state !== null ? "Edit" : "Create"} Brand  {state?.brandCode}
          </h3>
        </div>
        <main id="main" className="main">
          <section className="section">
            <div className="row">
              <div className="col-lg-12">
                <div
                  style={{
                    margin: 20,
                    backgroundColor: "#fff",
                    paddingTop: 10,
                    paddingBottom: 1,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 20,
                    cursor: "pointer",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                    marginBottom: 15,
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <div className="mt-3">
                      <h3>Brand Info</h3>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                          Brand Name
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="name"
                          name="name"
                          value={input.name}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              name: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.name}</small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="mobileNo" className="form-label">
                          Mobile No
                        </label>
                        <InputText
                          type="number"
                          style={{ width: "100%" }}
                          id="mobileNo"
                          name="mobileNo"
                          value={input.mobileNo}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              mobileNo: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.mobileNo}</small>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email Id
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="email"
                          name="email"
                          value={input.email}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              email: e.target.value.replace(/ /g, ", "),
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.email}</small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="notifyEmail" className="form-label">
                          Notify Email
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="notifyEmail"
                          name="notifyEmail"
                          value={input.notifyEmail}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              notifyEmail: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.notifyEmail}</small>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="notifyMobileNo" className="form-label">
                          Notify Mobile No
                        </label>
                        <InputText
                          type="number"
                          style={{ width: "100%" }}
                          id="notifyMobileNo"
                          name="notifyMobileNo"
                          value={input.notifyMobileNo}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              notifyMobileNo: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.notifyMobileNo}</small>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <h3>Brand Address</h3>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="addressLine1" className="form-label">
                          Address Line1
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="addressLine1"
                          name="addressLine1"
                          value={input.addressLine1}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              addressLine1: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.addressLine1}</small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="addressLine2" className="form-label">
                          Address Line2
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="addressLine2"
                          name="addressLine2"
                          value={input.addressLine2}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              addressLine2: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="city" className="form-label">
                          City
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="city"
                          name="city"
                          value={input.city}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              city: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="landmark" className="form-label">
                          Landmark
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="landmark"
                          name="landmark"
                          value={input.landmark}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              landmark: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="state" className="form-label">
                          State
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="state"
                          name="state"
                          value={input.state}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              state: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.state}</small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pin" className="form-label">
                          Pin Code
                        </label>
                        <InputText
                          type="number"
                          style={{ width: "100%" }}
                          id="pin"
                          name="pin"
                          value={input.pin}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              pin: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.pin}</small>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <h3>Additional Info</h3>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="flex align-items-center">
                          <Checkbox
                            inputId="wareHouseModel"
                            name="wareHouseModel"
                            value="wareHouseModel"
                            onChange={(e) =>
                              setWareHouseModelChecked(e.checked)
                            }
                            checked={wareHouseModelChecked}
                          />
                          <label htmlFor="wareHouseModel" className="ml-2">
                            Ware House Model
                          </label>
                        </div>
                        <div className="text-danger"> </div>
                      </div>
                      <div className="col-md-6">
                        <div className="flex align-items-center">
                          <Checkbox
                            inputId="offerPriceEnabled"
                            name="offerPriceEnabled"
                            value="offerPriceEnabled"
                            onChange={(e) =>
                              setOfferPriceEnabledChecked(e.checked)
                            }
                            checked={offerPriceEnabledChecked}
                          />
                          <label htmlFor="offerPriceEnabled" className="ml-2">
                            Offer Price Enabled
                          </label>
                        </div>
                        <div className="text-danger"></div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="flex align-items-center">
                          <Checkbox
                            inputId="brandOffModel"
                            name="brandOffModel"
                            value="brandOffModel"
                            onChange={(e) => setBrandOffModelChecked(e.checked)}
                            checked={brandOffModelChecked}
                          />
                          <label htmlFor="brandOffModel" className="ml-2">
                            Brand Off Model
                          </label>
                        </div>
                        <div className="text-danger"> </div>
                      </div>
                      <div className="col-md-6">
                        <div className="flex align-items-center">
                          <Checkbox
                            inputId="orderEditModel"
                            name="orderEditModel"
                            value="orderEditModel"
                            onChange={(e) =>
                              setOrderEditModelChecked(e.checked)
                            }
                            checked={orderEditModelChecked}
                          />
                          <label htmlFor="orderEditModel" className="ml-2">
                            Order Edit Model
                          </label>
                        </div>
                        <div className="text-danger"></div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="flex align-items-center">
                          <Checkbox
                            inputId="webLoginEnabled"
                            name="webLoginEnabled"
                            value="webLoginEnabled"
                            onChange={(e) =>
                              setWebLoginEnabledChecked(e.checked)
                            }
                            checked={webLoginEnabledChecked}
                          />
                          <label htmlFor="webLoginEnabled" className="ml-2">
                            Web Login Enabled
                          </label>
                        </div>
                        <div className="text-danger"></div>
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <button
                        type="submit"
                        className="move-btn move-btn-su mb-4"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Container>
    </div>
  );
};

export default CreateBrand;
